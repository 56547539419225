import './App.css';
import catGif from "./assets/cat.gif"
function App() {
  return (
    <div className="App">
      <div>
      <h3>
        THIS WEBSITE IS STILL UNDER CONSTRUCTION
      </h3>
      <h3>
        WEBSITE COMING SOON
      </h3>
      </div>
      <div>
        <img src = {catGif} alt = "funny cat gif " width = "500" height = "500"></img>
      </div>
    </div>
  );
}

export default App;